import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { routes } from './routes'
import modules from '@/store/modules'
import { SplitAttribute } from '@/utils/enum'

Vue.use(VueRouter)
Vue.use(VueMeta)

const { auth, split } = modules

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  modules.app.saveLastRoute(from)
  if (auth.userId) {
    try {
      await Promise.all([
        modules.auth.refreshUser(),
        modules.app.fetchSystemParameters(),
        modules.featureToggle.fetchFeatureToggles(),
      ])

      split.setClientAttributes({
        [SplitAttribute.OperatorMarket]:
          modules?.auth?.company?.address?.nearestMarketId,
      })
      await split.updateKey(auth?.company?.companyId?.toString())
    } catch (error) {
      console.warn('Authentication error:', error)
      auth.logout()
    }
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (!requiresAuth) {
    next()
  } else if (!auth.isTokenSet) {
    next({
      name: 'login',
      query: {
        redirectFrom: to.fullPath,
      },
    })
  } else if (auth.isDriverOnly || auth.isChaperoneOnly) {
    next({ name: 'download-app' })
  } else {
    next()
  }
})

export default router
